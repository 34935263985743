.content {
  width: 100%;
  max-width: 900px;
}

.form {
  @apply flex md:h-full w-full md:w-1/2 flex-grow mt-4 md:mt-0 p-2 md:p-4 lg:p-6 xl:p-16 justify-center flex-shrink-0;
}

.illustration {
  @apply relative h-56 md:h-full w-full md:w-1/2 flex-grow p-2 md:p-4 lg:p-6 xl:p-16 justify-center flex-shrink-0 items-center;
}

.illustration::after {
  @apply hidden md:flex absolute inset-0 bg-black opacity-0 transition-opacity duration-300;
  content: '';
  z-index: 1;
}

.illustration:hover::after {
  @apply absolute inset-0 z-0 bg-ink-1000 opacity-10;
}
