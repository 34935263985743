.shadow-left,
.shadow-right {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 20px;
  border-radius: 20px;
  z-index: 10;
  pointer-events: none;
  user-select: none;
  transition: opacity 0.2s;
  opacity: 0;
}

.shadow-left {
  left: -12px;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.shadow-right {
  right: -12px;
  background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
