@layer components {
  .dot {
    @apply block relative flex-shrink-0 bg-ink-000 border border-black rounded-full;
  }
  :global(.dark) .dot {
    @apply bg-ink-300;
  }

  .dot::after {
    @apply bg-white border border-black rounded-full;

    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    flex-shrink: 0;
    transition: transform 0.25s;
  }

  .dot.sm {
    width: 24px;
    height: 14px;
    border-radius: 21px;
  }

  .dot.sm::after {
    width: 14px;
    height: 14px;
  }

  .dot.md {
    width: 40px;
    height: 24px;
    border-radius: 36px;
  }

  .dot.md::after {
    width: 24px;
    height: 24px;
  }

  .input:checked + .dot {
    @apply bg-active;
  }

  .input:checked + .dot.sm::after {
    transform: translateX(10px);
  }

  .input:checked + .dot.md::after {
    transform: translateX(16px);
  }
}
