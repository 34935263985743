@layer components {
  .input {
    @apply block w-full border shadow-active-none rounded text-component leading-input bg-ink-000;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: 0;
    box-shadow: none;
    transition-property: border-color, box-shadow, background;
    transition-duration: 250ms;
    text-align: inherit;
  }

  :global(.dark) .input {
    @apply bg-ink-000;
  }

  .input:focus {
    @apply border-active shadow-active;

    outline-offset: -2px;
  }

  .input:invalid:not(:focus),
  input.error:not(:focus) {
    @apply border-danger;
  }

  .input[disabled] {
    @apply opacity-60 pointer-events-none cursor-default;
  }

  .variant-outlined {
    @apply bg-ink-000 text-ink-900 border-ink-100;
  }

  .variant-ghost {
    @apply bg-ink-000 text-ink-900 border-transparent;
  }

  :global(.dark) .variant-outlined {
    @apply bg-ink-000 border-ink-200;
  }

  .variant-outlined:-webkit-autofill {
    box-shadow: 0 0 0 30px var(--color-ink-000) inset !important;
    color: var(--color-ink-700) !important;
    -webkit-text-fill-color: var(--color-ink-700) !important;
  }

  /* Small 24px */
  .size-sm {
    @apply h-6;
  }

  .padding-sm {
    @apply px-2;
  }

  .size-sm--icon-left {
    @apply pl-8;
  }

  .size-sm--icon-right {
    @apply pr-8;
  }

  /* Medium 32px */
  .size-md {
    @apply h-8;
  }

  .padding-md {
    @apply px-2;
  }

  .size-md--icon-left {
    @apply pl-8;
  }

  .size-md--icon-right {
    @apply pr-8;
  }

  /* Large 40px */
  .size-lg {
    @apply h-10;
  }

  .padding-lg {
    @apply px-3;
  }

  .size-lg--icon-left {
    @apply pl-9;
  }

  .size-lg--icon-right {
    @apply pr-9;
  }

  /* Extra Large 48px */
  .size-xl {
    @apply h-12;
  }

  .padding-xl {
    @apply px-3;
  }

  .size-xl--icon-left {
    @apply pl-9;
  }

  .size-xl--icon-right {
    @apply pr-9;
  }
}
