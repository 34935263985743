@layer components {
  .box {
    @apply w-4 h-4 relative flex-shrink-0 cursor-pointer rounded-sm border border-ink-1000;
  }

  .tick {
    @apply border-white absolute;

    top: 3px;
    left: 2px;
    width: 10px;
    height: 5px;
    transform: rotateZ(-45deg) scale(0.75);
    opacity: 0;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
    border-width: 0 0 2px 2px;
  }

  input:focus + .box {
    @apply border-active;
  }

  input:checked + .box {
    @apply border-active bg-active;
  }

  input:checked + .box .tick {
    transform: rotateZ(-45deg) scale(1);
    opacity: 1;
  }
}
