/* purgecss start ignore */

.styles_tip__Vi4MI {
  background-color: var(--color-ink-1000);
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-ink-000);
}

.styles_tip__Vi4MI .tippy-arrow {
  color: var(--color-ink-1000);
}

/* purgecss end ignore */

/* purgecss start ignore */

.styles_shadow-left__EbBvQ,
.styles_shadow-right__1wGQa {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 20px;
  border-radius: 20px;
  z-index: 10;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: opacity 0.2s;
  opacity: 0;
}

.styles_shadow-left__EbBvQ {
  left: -12px;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.styles_shadow-right__1wGQa {
  right: -12px;
  background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

/* purgecss end ignore */

/* purgecss start ignore */

.styles_container__gNePc {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 768px) {
  .styles_container__gNePc {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.styles_container__gNePc {
  max-width: 1200px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.styles_code__idLjM {
  border-color: #cadbec;
  background-color: #f0f7fe;
}

.dark .styles_code__idLjM {
  border-color: var(--color-ink-100);
  background-color: var(--color-ink-000);
}

/* purgecss end ignore */

.styles_box__3FICh {
  position: relative;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: var(--color-ink-1000);
}

.styles_tick__LqH74 {
  position: absolute;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  top: 3px;
  left: 2px;
  width: 10px;
  height: 5px;
  transform: rotateZ(-45deg) scale(0.75);
  opacity: 0;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  border-width: 0 0 2px 2px;
}

input:focus + .styles_box__3FICh {
  border-color: var(--color-primary-active-main);
}

input:checked + .styles_box__3FICh {
  border-color: var(--color-primary-active-main);
  background-color: var(--color-primary-active-main);
}

input:checked + .styles_box__3FICh .styles_tick__LqH74 {
  transform: rotateZ(-45deg) scale(1);
  opacity: 1;
}

.styles_input__VlIl_ {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  background-color: var(--color-ink-000);
  font-size: 0.875rem;
  line-height: 1.125rem;
  --tw-shadow: inset 0 0 0 0 var(--color-primary-active-main);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: 0;
  box-shadow: none;
  transition-property: border-color, box-shadow, background;
  transition-duration: 250ms;
  text-align: inherit;
}

.dark .styles_input__VlIl_ {
  background-color: var(--color-ink-000);
}

.styles_input__VlIl_:focus {
  border-color: var(--color-primary-active-main);
  --tw-shadow: inset 0 0 0 1px var(--color-primary-active-main);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: -2px;
}

.styles_input__VlIl_:invalid:not(:focus), input.styles_error__2SsY3:not(:focus) {
  border-color: var(--color-primary-danger-main);
}

.styles_input__VlIl_[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.styles_variant-outlined__FWtX7 {
  border-color: var(--color-ink-100);
  background-color: var(--color-ink-000);
  color: var(--color-ink-900);
}

.styles_variant-ghost__AxhM6 {
  border-color: transparent;
  background-color: var(--color-ink-000);
  color: var(--color-ink-900);
}

.dark .styles_variant-outlined__FWtX7 {
  border-color: var(--color-ink-200);
  background-color: var(--color-ink-000);
}

.styles_variant-outlined__FWtX7:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-ink-000) inset !important;
  color: var(--color-ink-700) !important;
  -webkit-text-fill-color: var(--color-ink-700) !important;
}

/* Small 24px */

.styles_size-sm__PHYaG {
  height: 1.5rem;
}

.styles_padding-sm__oUmFN {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.styles_size-sm--icon-left__hZYMA {
  padding-left: 2rem;
}

.styles_size-sm--icon-right__lCkb7 {
  padding-right: 2rem;
}

/* Medium 32px */

.styles_size-md__tPxH5 {
  height: 2rem;
}

.styles_padding-md__2T9wO {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.styles_size-md--icon-left__Z4bEU {
  padding-left: 2rem;
}

.styles_size-md--icon-right__I47NJ {
  padding-right: 2rem;
}

/* Large 40px */

.styles_size-lg__5bUeh {
  height: 2.5rem;
}

.styles_padding-lg___TEn4 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.styles_size-lg--icon-left__0l_I_ {
  padding-left: 2.25rem;
}

.styles_size-lg--icon-right__CmtBi {
  padding-right: 2.25rem;
}

/* Extra Large 48px */

.styles_size-xl__ZBV8a {
  height: 3rem;
}

.styles_padding-xl__c_G39 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.styles_size-xl--icon-left__G7F_B {
  padding-left: 2.25rem;
}

.styles_size-xl--icon-right__XjOnO {
  padding-right: 2.25rem;
}

/* purgecss start ignore */

.styles_content__wXfRu {
  width: 100%;
  max-width: 900px;
}

.styles_form__6jqmZ {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .styles_form__6jqmZ {
    margin-top: 0px;
    height: 100%;
    width: 50%;
    padding: 1rem;
  }
}

@media (min-width: 976px) {
  .styles_form__6jqmZ {
    padding: 1.5rem;
  }
}

@media (min-width: 1440px) {
  .styles_form__6jqmZ {
    padding: 4rem;
  }
}

.styles_illustration__vdgKe {
  position: relative;
  height: 14rem;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .styles_illustration__vdgKe {
    height: 100%;
    width: 50%;
    padding: 1rem;
  }
}

@media (min-width: 976px) {
  .styles_illustration__vdgKe {
    padding: 1.5rem;
  }
}

@media (min-width: 1440px) {
  .styles_illustration__vdgKe {
    padding: 4rem;
  }
}

.styles_illustration__vdgKe::after {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

@media (min-width: 768px) {
  .styles_illustration__vdgKe::after {
    display: flex;
  }
}

.styles_illustration__vdgKe::after {
  content: '';
  z-index: 1;
}

.styles_illustration__vdgKe:hover::after {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 0;
  background-color: var(--color-ink-1000);
  opacity: 0.1;
}

/* purgecss end ignore */

.styles_dot__v7_1I {
  position: relative;
  display: block;
  flex-shrink: 0;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  background-color: var(--color-ink-000);
}

.dark .styles_dot__v7_1I {
  background-color: var(--color-ink-300);
}

.styles_dot__v7_1I::after {
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  flex-shrink: 0;
  transition: transform 0.25s;
}

.styles_dot__v7_1I.styles_sm__Q5DK_ {
  width: 24px;
  height: 14px;
  border-radius: 21px;
}

.styles_dot__v7_1I.styles_sm__Q5DK_::after {
  width: 14px;
  height: 14px;
}

.styles_dot__v7_1I.styles_md__6RXQz {
  width: 40px;
  height: 24px;
  border-radius: 36px;
}

.styles_dot__v7_1I.styles_md__6RXQz::after {
  width: 24px;
  height: 24px;
}

.styles_input__z2Hx3:checked + .styles_dot__v7_1I {
  background-color: var(--color-primary-active-main);
}

.styles_input__z2Hx3:checked + .styles_dot__v7_1I.styles_sm__Q5DK_::after {
  transform: translateX(10px);
}

.styles_input__z2Hx3:checked + .styles_dot__v7_1I.styles_md__6RXQz::after {
  transform: translateX(16px);
}

